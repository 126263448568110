import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const REAMAZE_JS_CONTAINER_ID = 'reamazejs-container';

function ReamazeWidget(props) {
  useEffect(() => {
    const reamazejs = document.createElement('script');
    reamazejs.src = props.reamazeJsSource;
    reamazejs.async = true;
    reamazejs.type = 'text/javascript';
    document.body.appendChild(reamazejs);

    window._support = {
      account: props.reamazeBrandId,
      openPopup: props.forceShowFlyout,
      ui: {
        contactMode: 'default',
        enableKb: 'false',
        styles: {
          widgetColor: props.color
        },
        widget: {
          icon: 'webChat',
          label: {
            mode: 'notification',
            text: props.prompt
          },
          position: props.position
        },
        shoutboxFacesMode: 'custom',
        faces: [props.avatar],
        overrides: {
          confirmationMessage: props.confirmationMessage
        }
      },
      user: {}
    };

    return () => {
      const rmz = document.querySelector(`script[src='${props.reamazeJsSource}']`);
      rmz.parentNode.removeChild(rmz);

      const reamazeContainer = document.getElementById(REAMAZE_JS_CONTAINER_ID);
      reamazeContainer.parentNode.removeChild(reamazeContainer);
    };
  }, []);

  useEffect(() => {
    // need to wait .5 seconds for the reamaze container to exist
    setTimeout(() => {
      const wrapper = props.reamazeWrap.current;
      const reamazeContainer = document.getElementById(REAMAZE_JS_CONTAINER_ID);

      if (!!wrapper && !!reamazeContainer && !wrapper.children.namedItem('reamazejs-container')) {
        // if both elements exist but wrapper doesn't have reamazeContainer appended to it yet
        wrapper.append(reamazeContainer);
      }
    }, 500);
  });

  useEffect(() => {
    window._support.ui.widget.label.text = props.prompt;
    window._support.ui.widget.position = props.position;
    window._support.ui.styles = { widgetColor: props.color };
    window._support.ui.shoutboxFacesMode = 'custom';
    window._support.ui.faces = [props.avatar];
    window._support.openPopup = props.forceShowFlyout;

    if (typeof window.reamaze !== 'undefined') {
      // call it twice because that's what works
      window.reamaze.reload();
      window.reamaze.reload();
    }
  }, [props.position, props.prompt, props.color, props.avatar, props.forceShowFlyout]);

  return <div></div>;
}

ReamazeWidget.propTypes = {
  position: PropTypes.string,
  prompt: PropTypes.string,
  color: PropTypes.string,
  reamazeBrandId: PropTypes.string,
  reamazeJsSource: PropTypes.string,
  reamazeWrap: PropTypes.node,
  confirmationMessage: PropTypes.string,
  avatar: PropTypes.string,
  forceShowFlyout: PropTypes.bool
};

export default ReamazeWidget;
