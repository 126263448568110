export const MESSAGING_ENABLED = 'enabled';
export const NOTIFICATION_PREFERENCE = 'notificationPreference';
export const SEND_MESSAGE_TO = 'formEmail';
export const WELCOME_MESSAGE = 'welcomeMessage';
export const FORM_SUCCESS_MESSAGE = 'formSuccessMessage';
export const EMAIL_OPT_IN_ENABLED = 'emailOptInEnabled';
export const EMAIL_OPT_IN_MESSAGE = 'emailOptInMessage';
export const REAMAZE_POSITION = 'reamazePosition';
export const REAMAZE_THEME_COLOR = 'reamazeThemeColor';
export const REAMAZE_PROMPT_ENABLED = 'reamazePromptEnabled';
export const REAMAZE_PROMPT = 'reamazePrompt';
export const REAMAZE_CONFIRMATION_MESSAGE = 'reamazeConfirmationMessage';
export const REAMAZE_AVATAR_IMAGE = 'reamazeAvatarImage';
